<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Voter Tags</div>
    <div class="
          col-lg-7 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
      <div v-if="this.showtagaddbuttonstatus">
        <button v-if="this.allowtagadditionaccess == 1" type="button" @click="addTagModalOpen()"
          class="header-btn-outer btn btn-primary">
          Add Tag
        </button>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink active" id="myvoter-all-details-tab" data-bs-toggle="tab"
        data-bs-target="#myvoteralldetail" type="button" role="tab" aria-controls="vehicle-details" aria-selected="true"
        :disabled="loading" @click="tagAddButtonShow()"><i class="pi pi-tags me-1"
          :style="{ fontSize: '0.7rem' }"></i>Voter Tags
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink" id="teamvoter-report-tab" data-bs-toggle="tab"
        data-bs-target="#teamvoterreport" type="button" role="tab" aria-controls="customer-details"
        aria-selected="false" :disabled="loading" @click="tagAddButtonHide()">
        <i class="pi pi-user-plus me-1" :style="{ fontSize: '0.7rem' }"></i>Voter Tags Approvers
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="myvoteralldetail" role="tabpanel"
      aria-labelledby="myvoter-all-details-tab">
      <div class="custom-ultima-datatable" style="height: calc(100vh - 185px)">
        <DataTable :value="tagList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
          :rows="30" responsiveLayout="scroll" :lazy="true" :loading="loading" :rowHover="true"
          :totalRecords="totalRecords" @page="changePage($event)"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" dataKey="au1">
          <template v-if="!loading" #empty>No records found.</template>
          <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
          <Column field="Model" header="Tag Name" headerStyle="width: 60%" bodyStyle="width: 60%">
            <template #body="{ data }">
              <div>
                <span class="label-heading text-capitalize">{{
                  data.au2 ? data.au2 : "N/A"
                }}</span>
              </div>
            </template>
          </Column>
          <Column field="status" header="Status" headerStyle="width: 20%" bodyStyle="width: 20%">
            <template #body="{ data }">
              <div>
                <span v-if="data.au3 == 1" class="status-active ms-2">Active</span>
                <span v-if="data.au3 == 0" class="status-inactive ms-2">Inactive</span>
              </div>
            </template>
          </Column>
          <Column field="Action" header="Action" class="justify-content-center" headerStyle="width: 20%"
            bodyStyle="width: 20%">
            <template #body="{ data }">
              <button :disabled="this.allowtagremoveaccess == 0" type="button" class="btn custom-view-detail-btn"
                @click="editTagModalOpen(data)">
                Edit Details
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="tab-pane fade" id="teamvoterreport" role="tabpanel" aria-labelledby="teamvoter-report-tab">
      <div class="search-voter-section-outer" style="height: calc(100vh - 185px);">
        <div class="layout-content-section">
          <div class="form-box-outer">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="intelligent-campaign-tab" data-bs-toggle="tab"
                      data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                      aria-controls="intelligent-campaign" aria-selected="true">
                      Tag Addition Approvers
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="whatsapp-campaign-tab" data-bs-toggle="tab"
                      data-bs-target="#whatsappcampaigntarget" type="button" role="tab"
                      aria-controls="whatsapp-campaign" aria-selected="false">
                      Tag Removal Approvers
                    </button>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="intelligentcampaigntarget" role="tabpanel"
                    aria-labelledby="intelligent-campaign-tab">
                    <div class="row py-3">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="custom-form-group">
                          <label for="formEffectedInput"
                            class="form-label d-flex justify-content-between"><span>Select Approver Users<span class="text-danger">*</span></span><small class="muted-text text-danger">(Minimum 2 Approvers Required)</small></label>
                          <Multiselect v-model="addition.approveruser" :options="userList" mode="tags" :createTag="false"
                            label="label" class="multiselect-custom form-custom-select text-capitalize"
                            placeholder="Select" :closeOnSelect="true" :searchable="true" :object="true" />
                          <div class="custom-error" v-if="v$.addition.approveruser.$error">
                            {{ v$.addition.approveruser.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 text-end">
                          <button type="button" class="btn modal-bulk-next-btn" @click="submitAdditionApproverBtn()" :disabled="showadditionloader || this.addition.approveruser.length < 2" :style="{width: '115px'}">
                              <span v-if="!showadditionloader">Submit</span>
                              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                  v-if="showadditionloader"></div>
                          </button>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="whatsappcampaigntarget" role="tabpanel"
                    aria-labelledby="whatsapp-campaign-tab">
                    <div class="row py-3">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="custom-form-group">
                          <label for="formEffectedInput"
                            class="form-label d-flex justify-content-between"><span>Select Approver Users<span class="text-danger">*</span></span><small class="muted-text text-danger">(Minimum 2 Approvers Required)</small></label>
                          <Multiselect v-model="removal.approveruser" :options="userList" mode="tags" :createTag="false"
                            label="label" class="multiselect-custom form-custom-select text-capitalize"
                            placeholder="Select" :closeOnSelect="true" :searchable="true" :object="true" />
                          <div class="custom-error" v-if="v$.removal.approveruser.$error">
                            {{ v$.removal.approveruser.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 text-end">
                          <button type="button" class="btn modal-bulk-next-btn" @click="submitRemovalApproverBtn()" :disabled="showremovalloader || this.removal.approveruser.length < 2" :style="{width: '115px'}">
                              <span v-if="!showremovalloader">Submit</span>
                              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                  v-if="showremovalloader"></div>
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="addTagDialog">
    <div class="modal-dialog modal-md modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Add Tag</h5>
          <button type="button" class="btn-close" @click="addeditTagModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label for="formTagNameInput" class="form-label">Tag Name</label>
                <input type="text" v-model="add.tagname" class="form-control text-capitalize" id="formTagNameInput"
                  placeholder="Enter Tag Name" autocomplete="off" maxlength="25" />
                <div class="custom-error" v-if="v$.add.tagname.$error">
                  {{ v$.add.tagname.$errors[0].$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">Status<span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="add.tagstatus" name="active-status" id="active"
                    autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                  <input type="radio" class="btn-check" v-model="add.tagstatus" name="inactive-satatus" id="inactive"
                    autocomplete="off" value="0" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                </div>
                <div class="custom-error" v-if="v$.add.tagstatus.$error">
                  {{ v$.add.tagstatus.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-12 text-end">
            <button type="button" class="btn user-role-btn text-center" @click="addNewTag" :disabled="showloader">
              <span v-if="!showloader">Save</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-mask" v-if="editRoleDialog">
    <div class="modal-dialog modal-md modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Tag</h5>
          <button type="button" class="btn-close" @click="addeditTagModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-fixed-height">
            <div v-if="!showmodalloader">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label for="formTagNameInput" class="form-label">Tag Name</label>
                    <input type="text" v-model="edit.tagname" class="form-control text-capitalize" id="formTagNameInput"
                      placeholder="Enter Tag Name" autocomplete="off" maxlength="25" />
                    <div class="custom-error" v-if="v$.edit.tagname.$error">
                      {{ v$.edit.tagname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Status<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="edit.tagstatus" name="active-status" id="active"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                      <input type="radio" class="btn-check" v-model="edit.tagstatus" name="inactive-satatus"
                        id="inactive" autocomplete="off" value="0" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                    </div>
                    <div class="custom-error" v-if="v$.edit.tagstatus.$error">
                      {{ v$.edit.tagstatus.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-12 text-end">
            <button type="button" class="btn user-role-btn text-center" @click="updateVoterTag" :disabled="showloader">
              <span v-if="!showloader">Update</span>
              <div class="
                          spinner-border
                          text-light
                          custom-spinner-loader-btn
                        " role="status" v-if="showloader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      loading: false,
      tagList: [],
      addTagDialog: false,
      editRoleDialog: false,
      add: {
        tagname: '',
        tagstatus: "",
      },
      edit: {
        tagname: '',
        tagstatus: "",
      },
      showloader: false,
      errormsg: "",
      roleid: "",
      showmodalloader: false,
      totalRecords: 0,
      page_no: 0,
      user_info: '',
      allowtagadditionaccess: '',
      allowtagremoveaccess: '',
      showtagaddbuttonstatus: true,
      addition: {
        approveruser: [],
      },
      userList: [],
      showadditionloader: false,
      tagapproverNameList: '',
      tagaddid: '',

      removal: {
        approveruser: [],
      },
      showremovalloader: false,
      tagremovalNameList: '',
      tagremoveid: '',
    };
  },
  validations() {
    return {
      add: {
        tagname: { required: helpers.withMessage("Please enter tag name", required) },
        tagstatus: {
          required: helpers.withMessage("Please select status", required),
        },
      },
      edit: {
        tagname: { required: helpers.withMessage("Please enter tag name", required) },
        tagstatus: {
          required: helpers.withMessage("Please select status", required),
        },
      },
      addition: {
        approveruser: { required: helpers.withMessage("Please select approvers", required) },
      },
      removal: {
        approveruser: { required: helpers.withMessage("Please select approvers", required) },
      }
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.gettaglist();
    this.getactiveusers();
    this.getAdditionList();
    this.getRemovalList();
    this.user_info = JSON.parse(localStorage.user);
    this.allowtagadditionaccess = this.user_info.ak197;
    this.allowtagremoveaccess = this.user_info.ak198;
  },
  methods: {
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.userList = data.data;
          // for (let i = 0; i < this.userList.length; i++) {
          //   let designation = '';
          //   if (this.userList[i]['ak84'] != null && this.userList[i]['ak84'] != '') {
          //     designation = ' (' + this.userList[i]['ak84'] + ')';
          //   }
          //   this.userList[i]['label'] = this.userList[i]['label'] + '' + designation;
          // }
        } else {
          this.userList = [];
        }
      });
    },
    getAdditionList() {
      this.tagapproverNameList = '';
      this.tagaddid = '';
      this.ApiService.getTagRequestApprovalOrRemovalUsersList({request_type: 1}).then((data) => {
        if (data.status == 200) {
          this.tagapproverNameList = data.data;
          this.tagaddid = data.data.ds1;
          if(this.tagapproverNameList != '') {
            let arrConverted = this.tagapproverNameList.ds3.split(',');
            for (let index = 0; index < arrConverted.length; index++) {
                let vals = this.userList.filter(function (item) {
                    return item.value == arrConverted[index];
                });
                if (vals.length > 0) {
                  this.addition.approveruser = [...this.addition.approveruser, vals[0]];
                }
            }
          }
        } else {
          this.tagapproverNameList = [];
        }
      });
    },
    getRemovalList() {
      this.tagremovalNameList = '';
      this.tagremoveid = '';
      this.ApiService.getTagRequestApprovalOrRemovalUsersList({request_type: 2}).then((data) => {
        if (data.status == 200) {
          this.tagremovalNameList = data.data;
          this.tagremoveid = data.data.ds1;
          if(this.tagremovalNameList != '') {
            let arrConvertedremove = this.tagremovalNameList.ds3.split(',');
            for (let index = 0; index < arrConvertedremove.length; index++) {
                let vals = this.userList.filter(function (item) {
                    return item.value == arrConvertedremove[index];
                });
                if (vals.length > 0) {
                  this.removal.approveruser = [...this.removal.approveruser, vals[0]];
                }
            }
          }
        } else {
          this.tagremovalNameList = [];
        }
      });
    },
    gettaglist(item) {
      this.loading = true;
      this.ApiService.gettaglist(item).then((data) => {
        if (data.status == 200) {
          this.tagList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.tagList = '';
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.gettaglist({ page_no: this.page_no });
    },
    addTagModalOpen() {
      this.addTagDialog = true;
      this.add.tagname = '';
      this.add.sttagstatusatus = '';
    },

    addeditTagModalClose() {
      this.editRoleDialog = false;
      this.addTagDialog = false;
    },

    editTagModalOpen(data) {
      this.showmodalloader = true;
      this.roleid = data.au1;
      this.edit.tagname = data.au2;
      this.edit.tagstatus = data.au3;
      this.editRoleDialog = true;
      this.showmodalloader = false;
    },
    addNewTag() {
      this.errormsg = '';
      this.v$.add.$validate();
      let fields = {};
      fields["au2"] = this.add.tagname;
      fields["au3"] = this.add.tagstatus;
      this.v$.$validate();
      if (!this.v$.add.$error) {
        this.showloader = true;
        this.ApiService.createtag(fields).then((items) => {
          if (items.success == true) {
            this.showloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.addTagDialog = false;
            this.gettaglist();
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      }
    },
    updateVoterTag() {
      this.errormsg = '';
      let fields = {};
      fields["au1"] = this.roleid;
      fields["au2"] = this.edit.tagname;
      fields["au3"] = this.edit.tagstatus;
      this.v$.edit.$validate();
      if (!this.v$.edit.$error) {
        this.showmodalloader = true;
        this.showloader = true;
        this.ApiService.createtag(fields).then((data) => {
          if (data.success == true) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.editRoleDialog = false;
            this.edit.tagname = '';
            this.edit.tagstatus = '';
            this.errormsg = "";
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.gettaglist();
            this.showmodalloader = false;
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
            this.showmodalloader = false;
          }
        });
      }
    },
    tagAddButtonShow() {
      this.showtagaddbuttonstatus = true;
    },
    tagAddButtonHide() {
      this.showtagaddbuttonstatus = false;
    },
    submitAdditionApproverBtn() {
      this.v$.addition.$validate();
      let fields = {};
      if(this.tagapproverNameList != '') {
        fields["ds1"] = this.tagaddid;
      }
      fields["ds2"] = 1;
      fields["ds3"] = JSON.stringify(this.addition.approveruser);
      this.v$.$validate();
      if (!this.v$.addition.$error) {
        this.showadditionloader = true;
        this.ApiService.updateTagRequestApprovalOrRemovalUser(fields).then((items) => {
          if (items.success == true) {
            this.showadditionloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showadditionloader = false;
          }
        });
      }
    },
    submitRemovalApproverBtn() {
      this.v$.removal.$validate();
      let fields = {};
      if(this.tagapproverNameList != '') {
        fields["ds1"] = this.tagremoveid;
      }
      fields["ds2"] = 2;
      fields["ds3"] = JSON.stringify(this.removal.approveruser);
      this.v$.$validate();
      if (!this.v$.removal.$error) {
        this.showremovalloader = true;
        this.ApiService.updateTagRequestApprovalOrRemovalUser(fields).then((items) => {
          if (items.success == true) {
            this.showremovalloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showremovalloader = false;
          }
        });
      }
    }
  },
};
</script>

<style scoped>
.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  margin-right: 12px;
  min-width: 80px;
}

.user-role-btn:focus {
  box-shadow: none;
}

.modal-body-fixed-height {
  min-height: 140px;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.form-box-outer {
  padding: 16px 16px;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  min-height: 405px;
}

.custom-vertical-modal-tab {
  width: 100%;
  min-width: 100%;
  height: 368px;
}
</style>